<template>
  <div 
    class="project-page-title"
  >
    <h2 
      class="title"
      :class="titleClass"
    >{{ title }}</h2>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    titleClass: {
      type:String,
      default: ''
    }
  }
}
</script>

<style scoped>
.project-page-title {
  margin-bottom: 20px;
}
.title {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}
.title::after {
  content: '';
  display: block;
  margin-top: 10px;
  width: 180px;
  height: 2px;
  background: #333;
}

.title.center {
  text-align: center;
}
.title.center::after {
  float: none;
  margin: 15px auto;
  width: 120px;
}
</style>