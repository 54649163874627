<template>
  <div 
    v-if="isLoaded()"
    class="
      duck-form 
      add--project--form
    "
  >
    <ProjectViewTitle 
      :title="project.name"
      titleClass="center"
    />
    
    <form
      @submit.prevent="saveForm"
      autocomplete="off"
    >
      <fieldset 
        class="
          form-block
          add--project--block
        "
      >
        <div 
          class="form-container"
        >

          <SmallTitle 
            title="ข้อมูลเพื่ออนุมัติการชนะประมูล" 
            class="bottom-line"
          />
          <div 
            class="input"
            :class="{ error: $v.form.budget_real.$error }"
          >
            <Label 
              text="งบก่อสร้างจริงในสัญญา" 
              :required=true
            />
            <NumberFloatInput 
              v-model="form.budget_real"
            />
          </div>
          
          <!--
            Display when 'business_type' is company only
          -->
          <div 
            v-if="project.winner_data.business_type == 9"
            class="input"
          >
            <Label 
              text="จะทำสัญญาแบบมี Vat. 7% ใช่หรือไม่ ?" 
              :required=true
            />
            <select v-model="form.vat_type">
              <option value="0">ทำสัญญาในนามบริษัท (มี Vat)</option>
              <option value="1">ทำสัญญาในนามบุคคลธรรมดา (ไม่คำนวนรวม Vat)</option>
            </select>
          </div>
          
          <ProjectSubmitBlock text="บันทึกข้อมูล" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
// load library and variables
import {apiRequest} from '@/utils/axios/axiosInstance.js';
const {required} = require('vuelidate/lib/validators');

// Load components
import Label              from '@/components/common/form/Label.vue';
import NumberFloatInput   from '@/components/common/form/input/NumberFloatInput.vue';
import ProjectViewTitle   from '@/components/project/view/block/ProjectViewTitle.vue';
import ProjectSubmitBlock from '@/components/project/form/button/ProjectSubmitBlock.vue';
import SmallTitle         from '@/components/common/SmallTitle.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  data() {
    return {
      projectId: '',
      project: {},
      form: {
        project_id: '',
        budget_real: '',
        vat_type: 0
      },
    }
  },

  /**
   * Validate form data before save to api
   */
  validations: {
    form: {
      budget_real: {required}
    },
  },

  /**
   * Registration components
   */
  components: {
    Label,
    NumberFloatInput,
    ProjectViewTitle,
    ProjectSubmitBlock,
    SmallTitle,
  },

  /**
   * Created
   */
  created() {
    this.projectId = this.$route.params.id;
    this.form.project_id = this.projectId;

    // set page title
    this.$store.commit('setPageTitle', 'Auction Complete');
    this.$store.commit('setHeadTitle', 'Auction Complete');

    // get project data
    this.setForm();
  },

  /**
   * Methods
   */
  methods: {
    /**
     * Detect page loaded
     */
    isLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * Get project data
     */
    setForm() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get('/project/' + this.projectId)
        .then( (res) => {
          if (res.data.status != 200) {
            alert('การโหลดข้อมูลมีปัญหา กรุณาลองรีเฟรชหน้านี้ใหม่อีกครั้งหรือกด Back เพื่อกลับไปหน้าเดิมของท่าน !!!')
          }else{
            this.project = res.data.result;
            
            this.form.approve = 9;
            this.form.complete = 9;
            this.form.kwanjai_code = 'kw-' + this.project.code;
            // console.log(this.project);

            this.$store.commit('setLoaderShow', false);
          }
        });
    },

    /**
     * Save value to database via API
     */
    saveForm() {
    //   // this.$v.$touch()
      this.$v.$touch();

      // if not validate error
      // update data to database by API
      if (!this.$v.$invalid) {
        // show main loader
        this.$store.commit('setLoaderShow', true);

        apiRequest
          .patch('/project/', this.form)
          .then( (res) => {
            if (res.data.status == 200) {
              this.$router.push('/project/view/' + this.projectId);
            } else {
              console.log(res.data);
              alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณารีเฟรชหน้านี้และลองใหม่อีกครั้ง !!!');
            }
          });
      }
    },
  }
}
</script>